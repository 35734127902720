const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/", "/about"],
    exact: true,
    component: "About",
  },
  {
    path: ["/", "/products"],
    exact: true,
    component: "Products",
  },
  {
    path: ["/", "/online3Dprintingservice"],
    exact: true,
    component: "Online3DPrintingService",
  },
  {
    path: ["/", "/MarketPlace"],
    exact: true,
    component: "MarketPlace",
  },
  {
    path: ["/", "/EcommerceIntegrations"],
    exact: true,
    component: "EcommerceIntegrations",
  },
  {
    path: ["/", "/APIPartner"],
    exact: true,
    component: "APIPartner",
  },
];

export default routes;
