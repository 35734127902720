import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import React from 'react';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import { Menu } from 'antd';
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  CustomMenu,
  CustomNavLinkSmall,
  CustomDropdown,
  HyperLinkWrapper,
  Label,
  Outline,
  Span,
} from "./styles";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };
  
  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    const goTo = (page: string) => {
      window.location.href = page;
    };

    const items: MenuProps['items'] = [
      {
        key: '1',
        label: (
          <HyperLinkWrapper rel="noopener noreferrer" href="Online3DPrintingService">
            Online 3D Printing Service
          </HyperLinkWrapper>
        ),
      },
      {
        key: '2',
        label: (
          <HyperLinkWrapper rel="noopener noreferrer" href="MarketPlace">
            Marketplace
          </HyperLinkWrapper>
        ),
      },
      {
        key: '3',
        label: (
          <HyperLinkWrapper rel="noopener noreferrer" href="EcommerceIntegrations">
            E-Commerce Integrations
          </HyperLinkWrapper>
        ),
      },
      {
        key: '4',
        label: (
          <HyperLinkWrapper rel="noopener noreferrer" href="APIPartner">
            API Partner
          </HyperLinkWrapper>
        ),
      },     
    ];

   
    return (
      <>
        <CustomNavLinkSmall onClick={() => goTo("home")}>
          <Span>{t("Home")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => goTo("about")}>
          <Span>{t("About")}</Span>
        </CustomNavLinkSmall>
        <CustomDropdown menu={{ items }}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
            {t("Products")}
              <DownOutlined />
            </Space>
          </a>
        </CustomDropdown>
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => scrollTo("contact")}
        >
          <Span>
            <Button>{t("Contact")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  ////
  type MenuItem2 = Required<MenuProps>['items'][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem2[],
    type?: 'group',
  ): MenuItem2 {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem2;
  }

  const item2s: MenuProps['items'] = [
    getItem((
      <HyperLinkWrapper rel="noopener noreferrer" href="home">
        Home
      </HyperLinkWrapper>
    ), '10'),
    getItem((
      <HyperLinkWrapper rel="noopener noreferrer" href="about">
        About
      </HyperLinkWrapper>
    ), '11'),
    getItem((
      <HyperLinkWrapper rel="noopener noreferrer" href="">
        Products
      </HyperLinkWrapper>
    ), 'sub', '', [
      getItem((
        <HyperLinkWrapper rel="noopener noreferrer" href="Online3DPrintingService">
          Online 3D Printing Service
        </HyperLinkWrapper>
      ), '12'),
      getItem((
        <HyperLinkWrapper rel="noopener noreferrer" href="MarketPlace">
          Marketplace
        </HyperLinkWrapper>
      ), '13'),
      getItem((
        <HyperLinkWrapper rel="noopener noreferrer" href="EcommerceIntegrations">
          E-Commerce Integrations
        </HyperLinkWrapper>
      ), '14'),
      getItem((
        <HyperLinkWrapper rel="noopener noreferrer" href="APIPartner">
          API Partner
        </HyperLinkWrapper>
      ), '15'),
    ]),
    getItem((
      <HyperLinkWrapper rel="noopener noreferrer" href="contact">
        Contact
      </HyperLinkWrapper>
    ), '16'),
  ];

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e);
  };


  ///

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.svg" width="101px" height="64px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
              <CustomMenu>Menu</CustomMenu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <Menu
            onClick={onClick}
            style={{ width: 256 }}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"
            items={item2s}
          />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
